.personSelect {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1.25rem;
  border: 2px solid #e5e5e5;
  background-color: white;
  z-index: 10;
  border-radius: 4px;
  border-top: transparent;

  div {

    span {
      display: flex;
    }

    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.multiModeHeading {
  font-weight: 600;
}

.personInput {
  position: relative;

  .MuiOutlinedInput-input {
    cursor: pointer !important;
  }
}

.largeIcon {
  @media screen and (min-width: 1023px) {
    display: none !important;
  }
}

.smallIcon {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}
