.nice-dates-navigation, .nice-dates-day {
  color: red;
}

.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 480px;
  transition: none;
}

.rangeContainer {
  .MuiOutlinedInput-root {
    padding-right: 0 !important;
  }
}

.errorMessage {
  color: #D4A976;
}

.closeDialog {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 1;
  border-radius: 50%;
  background-color: #0a0a0a;
  color: white;

  &:hover {
    background-color: #0a0a0a;
  }
}
