
.sideSearch {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media screen and (max-width: 1024px) {
    padding: 0 1rem;
  }

  .tripTypeContainer {
    width: 100%;
    order: 1;
    // margin-bottom: .5rem;
  }

  .searchType {
    width: 100%;
    order: 5;
  }

  .swapDirections {
    order: 2;
    cursor: pointer;
    margin-left: auto;
    margin-right: 40px;
    z-index: 1000;
    margin-top: -2rem;
    background-color: white;
    padding: 1rem;
    position: relative;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .personAndClassColumn {
    order: 4;
    display: flex;
    width: 100%;
  }

  .sideInputContainer {
    order: 2;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputRangeContainer {
    order: 3;
    margin-top: .5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .multiCityContainer {
    order: 5;
    width: 100%;
  }

  .mainSubmitContainerSide {
    order: 6;
    width: 100%;
  }

}

.sideSearchMinimized {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .tripTypeContainer {
    width: 100%;
    order: 1;
    margin-bottom: 1rem;
  }

  .swapDirections {
    order: 2;
    cursor: pointer;
    margin-left: auto;
    margin-right: 40px;
    z-index: 1000;
    margin-top: -2rem;
    background-color: white;
    padding: 1rem;
    position: relative;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .personAndClassColumn {
    order: 5;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }

  .searchType {
    width: 100%;
    order: 5;
  }

  .sideInputContainer {
    order: 2;
    margin-bottom: 10px;
    width: 100%;
  }

  .inputRangeContainer {
    order: 4;
    margin-top: .5rem;
    width: 100%;
  }

  .multiCityContainer {
    order: 2;
    width: 100%;
  }

  .mainSubmitContainerSide {
    order: 6;
    display: flex;
    flex-direction: column;
    margin-top: unset;
    width: 100%;

    .mainSubmitMulticityButtonContainer {
      height: 50px;
    }
  }

}

.mainSubmitContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}

.mainSubmitContainerSide {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  .mainSubmitMulticityButtonContainer {
    height: 50px;
  }
}

.mainSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px;
  flex-wrap: wrap;

  .personAndClassColumn {
    display: flex;
    width: 33%;
  }

  .mainSubmitButtonContainer {
    max-height: 56px;
    width: 10%;
  }

  .mainInputContainerMultiCity {
    width: 33%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  .multiCityContainer {
    margin-bottom: 1.25rem;
    width: 100%;

    .mainInputContainer {
      width: 33%;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .inputRangeContainer {
    div {
      width: 100% !important;
    }

    margin-right: 1.25rem;
    width: 33%;
  }

  .mainAutocompleteInputContainer {
    width: 29%;
  }

  .swapDirections {
    cursor: pointer;
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;

    &:hover {
      background-color: #00454A33;
    }
  }

  .mainPersonsInputContainer {
    width: 12%;
    align-self: self-start;
  }

  .mainInputRangeContainer {
    width: 33%;
  }

  .mainTripTypeInputContainer {
    width: 12%;
  }

  .mainSubmitMulticityButtonContainer {
    order: 1;

    width: 33%;
    height: 50px;
  }

  .mainSubmitButton {
    width: 10%;
  }

  .mainDepartureInputContainer {
    width: 33%;
  }

  .searchType {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
}


.searchHeading {
  font-size: 1.25rem;
  margin-bottom: .75rem;
  display: block;
  font-weight: normal;
}

.airlineCredentials {
  font-size: 2rem;
  line-height: 2.15rem;
}


.errorMessage {
  color: #D4A976;
}


