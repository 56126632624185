.tripTypeSelectContainer {
  display: flex;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  min-width: 355px;
  @media screen and (max-width: 1024px) {
    min-width: auto;
    margin-bottom: 15px;
  }

  .tripStateItem {
    padding: 1rem;
    color: #202020;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    transition: 300ms;
    &:hover{

      @media screen and (min-width: 1024px) {
        // background-color: #00454A1A;
        background-color: #3C6562;
      }
    }

    &.active {
      // background-color: #00454A33;
      background-color: #3C6562;
      color: white;
    }

    &.smallerFont {
      font-size: 13px;
    }
  }
}
